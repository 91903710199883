import React, { useEffect, useRef } from 'react';
import { VStack, Box, Flex, Spacer, Text, Wrap, WrapItem, Center, Container } from '@chakra-ui/react';
import Lottie from 'lottie-web';
import Lab from '../lotties/Lab.json';

import VideoPlayerDiabetes from '../components/VideoPlayerDiabetes';
import VideoPlayerHeartrate from '../components/VideoPlayerHeartrate';
import VideoPlayerCoughing from '../components/VideoPlayerCoughing';
import VideoPlayerParkinsons from '../components/VideoPlayerParkinsons';
import VideoPlayerRash from '../components/VideoPlayerRash';
import VideoPlayerSpineInjury from '../components/VideoPlayerSpineInjury';
import VideoPlayerToxins from '../components/VideoPlayerToxins';

import './TechnologyPage.css';

const LabAnimation = () => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Lab,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return <div ref={lottieContainer} />;
};
        
export default function TechnologyPage() {
    return (
    <VStack className="Technology-header">
            <Box fontSize='vlg' fontFamily='FFDaxProRegular' textAlign='center'>
            Labs and scans from your smartphone
            </Box>
            <Box pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            Our health journal boasts <b>patented messaging technologies</b> that automatically and intelligently activate sensors from your mobile device or wearables in search of data that could quantitatively describe your symptoms.
            </Box>
            <LabAnimation />
  
            <Box pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            Our AI prompts Joanna to provide blood glucose data from her wearable glycometer based on her texting-described symptoms, which is then seamlessly integrated into her text message.
            </Box>
            <VideoPlayerDiabetes />
            <Box pt={12} pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            While texting his symptoms, Lucas is prompted to provide heart rate data from his smartwatch, which is then automatically linked to his message.
            </Box>
            <VideoPlayerHeartrate />
            <Box pt={12} pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            Our AI prompts Vijay to record his cough based on his described symptoms, embedding the analyzed results into his message.
            </Box>
            <VideoPlayerCoughing />
            <Box pt={12} pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            Julia is prompted to take a picture while describing her skin condition, with the image analyzed and linked to her message.
            </Box>
            <VideoPlayerRash />
            <Box pt={12} pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            Our AI monitors Ruth's chronic condition by periodically recording her motor movements using her device's accelerometer and gyroscope, embedding these measurements into her text messaging logs.
            </Box>
            <VideoPlayerParkinsons />
            <Box pt={12} pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            Our AI guides a caregiver using augmented reality glasses to describe Leslie's symptoms in relation to a previously flagged area of concern. Each message is tagged and indexed by a precise medical description of the flagged area.
            </Box>
            <VideoPlayerSpineInjury />
            <Box pt={12} pb={8} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
            Based on Russell's self-reported symptoms via text, our AI requests his GPS location to check for any known environmental toxins in his vicinity that could potentially explain his symptoms, integrating this data with his messaging history.
            </Box>
            <VideoPlayerToxins />
    </VStack>
    )
};